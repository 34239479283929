import { comcastKeyForEvent } from './comcastKeyForEvent'
import { getKeyEventCode, getKeyEventName } from './getDeviceKeyForEvent'
import { tizenKeyForEvent } from './tizenKeyForEvent'
import { DeviceKey } from './types'
import { xboxKeyForEvent } from './xboxKeyForEvent'
export type DeviceKeysForEvent = [base: DeviceKey, tizen: DeviceKey, xbox: DeviceKey, comcast: DeviceKey]
export const keysForEvent = (event: KeyboardEvent): DeviceKeysForEvent => {
  const base: DeviceKey = {
    name: getKeyEventName(event),
    code: getKeyEventCode(event),
  }
  const comcast = comcastKeyForEvent(event)
  const tizen = tizenKeyForEvent(event)
  const xbox = xboxKeyForEvent(event)
  return [base, tizen, xbox, comcast]
}
