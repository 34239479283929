var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getWindowDeviceInfo } from '@grandstand-web/bally-web-shared/src/utils/getWindowDeviceInfo';
import { Logger } from '@grandstand-web/bally-web-shared/src/utils/logger';
const logger = Logger.of('Event Reporter: handleUserDetectableError');
export const handleError = (_a) => __awaiter(void 0, [_a], void 0, function* ({ type: errorType = 'other', code, description, userDetectable = false, }) {
    const deviceInfo = getWindowDeviceInfo();
    if (deviceInfo.platform === 'hbbtv') {
        try {
            const { Metrics } = yield import('@firebolt-js/sdk');
            const { errorTypeToComcastErrorType } = yield import('../helpers/errorTypeToComcastErrorType');
            const type = errorTypeToComcastErrorType(errorType);
            yield Metrics.error(type, code, description, userDetectable);
        }
        catch (error) {
            logger.error('Unable to report Metrics.error()', { error });
        }
    }
});
