import { getWindowDeviceInfo } from '@grandstand-web/bally-web-shared/src/utils/getWindowDeviceInfo'
import { getDefaultDeviceKey, getDeviceKeyForEvent } from './getDeviceKeyForEvent'
import { DeviceKey } from './types'

const comcastKeys: DeviceKey[] = [
  // Toggle DebugMenu keys
  { name: 'F16', code: 0 }, // plus button ("+" with red border)

  // Back keys
  { name: 'Escape', code: 8 }, // back button
  { name: 'Backspace', code: 8 }, // back button

  // A,B,C,D (i.e. rgyb keys on samsung remote)
  { name: 'ColorF0Red', code: 403 }, // A/Red button
  { name: 'ColorF1Green', code: 404 }, // B/Green button
  { name: 'ColorF2Yellow', code: 405 }, // C/Yellow button
  { name: 'ColorF3Blue', code: 406 }, // D/Blue button
]
export function comcastKeyForEvent(event: KeyboardEvent): DeviceKey {
  const fallback = getDefaultDeviceKey()
  try {
    const deviceInfo = getWindowDeviceInfo()
    if (deviceInfo.platform !== 'hbbtv' || event.key !== 'Unidentified') {
      // the key is already valid and shouldn't be matched with comcastKeys
      return fallback
    }
    return getDeviceKeyForEvent(event, comcastKeys)
  } catch (_) {
    return fallback
  }
}
