import { getWindowDeviceInfo } from '@grandstand-web/bally-web-shared/src/utils/getWindowDeviceInfo'
import { appClosing } from '@grandstand-web/event-reporter'

export const exitApplicationIfApplicable = async () => {
  const { platform } = getWindowDeviceInfo()
  await appClosing()
  if (platform === 'tv_samsung') {
    try {
      const ti = await import('tizen-common-web')
      ti.application.getCurrentApplication().exit()
    } catch (ex) {
      console.log(`Error exiting tizen => `, ex)
    }
  }
}
