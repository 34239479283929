import { GrandstandIcon } from '@grandstand-web/bally-web-shared/src/components/GrandstandIcon'
import styled from 'styled-components'

const PlayIconWrapper = styled.div`
  display: inline-flex;
  position: relative;
  overflow: visible;
  padding-right: 14px;
  height: 24px;
  width: 36px;
  justify-content: center;
  align-items: center;
`

const PlayIconStyle = styled.div`
  position: absolute;
  top: 1px;
`

const PlayIcon = () => {
  // samsung tizen doesn't display the ► charactor correctly, so we use the html code instead with
  // updated styles to display
  return (
    <PlayIconWrapper>
      <PlayIconStyle>
        <GrandstandIcon icon="player_controls_play" iconColor="white" />
      </PlayIconStyle>
    </PlayIconWrapper>
  )
}

export default PlayIcon
